import React, { Component } from "react";
import LayoutV1 from "markup/Layout/LayoutV1";
import PaymentCreditCardModal from "./PaymentCreditCardModal";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import * as initialEndpoint from "modules/Initial/_redux/initialEndpoint";
import * as authRedux from "modules/Auth/_redux/authRedux";
import { toast } from "react-toastify";

const pusher = new Pusher("27ef2bf6e16681849c0f", {
  cluster: "eu",
  encrypted: true,
});

let bnr3 = require("./../../images/lines.png");
let credit = require("./../../images/credit1.png");

class CheckoutPage extends Component {
  state = {
    show: false,
    items: [],
    packages: [],
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  componentDidMount() {
    const channel = pusher.subscribe(this.props.user.id);
    channel.bind("orderCompleted", this.redirectPage);

    initialEndpoint
      .getProducts()
      .then((res) => res.data.products)
      .then((result) => {
        this.setState((prevState) => ({
          ...prevState,
          packages: result,
        }));
      });
  }

  componentWillUnmount() {
    pusher.unsubscribe(this.props.user.email);
  }

  redirectPage = (remoteData) => {
    this.props.setBalance(remoteData.balance);
    toast(`Bakiyeniz ${remoteData.balance} TL olarak güncellendi.  `);

    this.props.history.push({
      pathname: "/",
      state: {
        remoteData,
      },
    });
  };

  selectProduct = (product) => {
    this.setState({
      show: true,
      items: [product],
    });
  };

  render() {
    return (
      <LayoutV1>
        <div className="page-content bg-white">
          <div className="content-block">
            <div
              className="section-full content-inner-2 overlay-white-middle"
              style={{
                backgroundImage: "url( " + bnr3 + ")",
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
              }}
            >
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="m-b0">
                    İlanları açmak için bakiye yükleyebilirsiniz{" "}
                  </h2>
                  <br />
                  <img src={credit} alt="" height={70} width={70} />
                </div>

                <div className="section-content box-sort-in button-example m-t80">
                  <div className="pricingtable-row">
                    <div className="row max-w1000 m-auto">
                      {this.props.products.map((item, index) => (
                        <div
                          key={index}
                          className="col-sm-12 col-md-4 col-lg-4 p-lr0 "
                        >
                          <div
                            className={
                              item.feature
                                ? "active pricingtable-wrapper style2"
                                : "pricingtable-wrapper style2"
                            }
                            style={{
                              background: item.feature
                                ? "rgb(70, 150, 113)"
                                : "white",
                            }}
                          >
                            <div className="pricingtable-inner">
                              <div className="pricingtable-price">
                                <h4
                                  className={
                                    item.feature
                                      ? "font-weight-300 m-t10 m-b0 text-white"
                                      : "font-weight-300 m-t10 m-b0"
                                  }
                                >
                                  {item.price}₺
                                </h4>
                              </div>
                              <p className={item.feature ? "text-white pt-2" : "pt-2"}>
                                Tahmini <b>{item.estimated}</b> ilan bağlantısı
                              </p>
                              <div className="m-t20">
                                <button
                                  onClick={() => this.selectProduct(item)}
                                  className={
                                    item.feature
                                      ? "site-button-secondry radius-xl shadow"
                                      : "site-button radius-xl shadow"
                                  }
                                  style={
                                    item.feature ? { background: "#fff" } : {}
                                  }
                                >
                                  <span
                                    className="p-lr30 font-weight-bold"
                                    style={{
                                      color: item.feature
                                        ? "rgb(70, 150, 113)"
                                        : "#fff",
                                    }}
                                  >
                                    Satın al{" "}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/*
                                            <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                                <div
                                                    className="pricingtable-wrapper style2 bg-primary text-white active">
                                                    <div className="pricingtable-inner">
                                                        <div className="pricingtable-price">

                                                            <h4 className="font-weight-300 m-t10 m-b0">50 AlCoin</h4>
                                                            <div className="pricingtable-bx">
                                                                <span>10</span>₺
                                                                İlan
                                                            </div>
                                                        </div>
                                                        <p>Tahmini 20 ilan bağlantısı</p>
                                                        <div className="m-t20">
                                                            <button onClick={() => this.selectProduct()}
                                                                    className="radius-xl">
																<span
                                                                    className="p-lr30 border radius-xl p-a10 "
                                                                    style={{color: 'white'}}>Satın al </span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                                <div className="pricingtable-wrapper style2 bg-white">
                                                    <div className="pricingtable-inner">
                                                        <div className="pricingtable-price">
                                                            <h4 className="font-weight-300 m-t10 m-b0">50 AlCoin</h4>
                                                            <div className="pricingtable-bx"><span>550</span>$ / Adet
                                                                İlan
                                                            </div>
                                                        </div>
                                                        <p>Tahmini 30 ilan bağlantısı</p>
                                                        <div className="m-t20">
                                                            <button onClick={() => this.selectProduct()}
                                                                    className="site-button radius-xl"><span
                                                                className="p-lr30">Satın al </span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>*/}
                    </div>
                  </div>
                </div>
              </div>

              <PaymentCreditCardModal
                show={this.state.show}
                items={this.state.items}
                handleClose={() => this.handleClose()}
              />
            </div>
          </div>
        </div>
      </LayoutV1>
    );
  }
}

//export default CheckoutPage;

const mapStateToProps = (state) => ({
  //items  : state.cartList.cart,
  user: state.auth.user,
  products: state.initial.products,
});

export default connect(mapStateToProps, authRedux.actions)(CheckoutPage);
